<template>
    <HeaderBar></HeaderBar>
    <div class="container">
      <div class="card my-5">
        <div class="card-body">
          <div class="container">
            <h1>Account Details</h1>
            <div v-if="user">
              <p><strong>Username:</strong> {{ user.email }}</p>
              <button class="btn btn-primary" @click="promptPasswordChange">
                Change Password
              </button>
              <button class="btn btn-danger" @click="signOut">
                Logout
              </button>
            </div>
            <div v-else>
              <p>Loading account details...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { signOut as firebaseSignOut, getAuth } from 'firebase/auth';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import HeaderBar from '../components/HeaderBar';
  
  export default {
    name: 'AccountDetails',
    components: {
      HeaderBar
    },
    setup() {
      const user = ref(null);
      const auth = getAuth();
      const router = useRouter(); // Initialize the router
  
      onMounted(() => {
        user.value = auth.currentUser;
      });
  
      const promptPasswordChange = () => {
            const newPassword = prompt('Enter your new password:');
            if (newPassword && user.value) {
                updatePassword(user.value, newPassword)
                    .then(() => {
                        alert('Password updated successfully!');
                    })
                    .catch((error) => {
                        if (error.code === 'auth/requires-recent-login') {
                            reauthenticateUser(newPassword);
                        } else {
                            alert(`Error: ${error.message}`);
                        }
                    });
            }
        };

        const reauthenticateUser = (newPassword) => {
            const currentPassword = prompt('Enter your current password:');
            if (currentPassword && user.value) {
                const credential = EmailAuthProvider.credential(user.value.email, currentPassword);
                reauthenticateWithCredential(user.value, credential)
                    .then(() => {
                        return updatePassword(user.value, newPassword);
                    })
                    .then(() => {
                        alert('Password updated successfully!');
                    })
                    .catch((error) => {
                        alert(`Error: ${error.message}`);
                    });
            }
        };
  
      const signOut = () => {
        firebaseSignOut(auth)
          .then(() => {
            // Redirect to login page
            router.push('/login');
          })
          .catch((error) => {
            alert(`Error signing out: ${error.message}`);
          });
      };
  
      return {
        user,
        promptPasswordChange,
        signOut
      };
    }
  };
  </script>
  
  <style scoped></style>