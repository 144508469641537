<template>
      <div style="margin-bottom: 30px;"></div>
        <div class="card">
          <div class="card-body">
            <div v-if="testResults">
              <div class="row">
                <div class="col">   
                    <h4 class="card-title">Tech Stack</h4>
                </div>
                <div class="mt-0 row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
                  <div class="col">
                    <div class="col">
                      <div class="card h-100">
                        <div class="card-header lead">
                          Technologies
                        </div>
                        <div
                          class="card-body mx-auto"
                          v-if="
                            testResults.techStack.Google ||
                            testResults.techStack.Facebook ||
                            testResults.techStack.Microsoft ||
                            testResults.techStack.Amazon ||
                            testResults.techStack.Salesforce ||
                            testResults.techStack.Atlassian ||
                            testResults.techStack.Cloudflare ||
                            testResults.techStack.Adobe ||
                            testResults.techStack.DocuSign ||
                            testResults.techStack.Mixpanel ||
                            testResults.techStack.OneTrust ||
                            testResults.techStack.Stripe ||
                            testResults.techStack.HubSpot ||
                            testResults.techStack.Twilio ||
                            testResults.techStack.Zendesk ||
                            testResults.techStack.SendGrid ||
                            testResults.techStack.Mailgun ||
                            testResults.techStack.Pardot ||
                            testResults.techStack.Zoom ||
                            testResults.techStack.Smartsheet ||
                            testResults.techStack.Dropbox ||
                            testResults.techStack.Drift ||
                            testResults.techStack.Miro ||
                            testResults.techStack.Klaviyo ||
                            testResults.techStack.Box ||
                            testResults.techStack.Airtable ||
                            testResults.techStack.Auth0 ||
                            testResults.techStack.Fastly ||
                            testResults.techStack.Segment ||
                            testResults.techStack.Notion ||
                            testResults.techStack.Slack ||
                            testResults.techStack.Intercom ||
                            testResults.techStack.Pendo ||
                            testResults.techStack.Dynatrace ||
                            testResults.techStack.Jamf ||
                            testResults.techStack.Mailjet ||
                            testResults.techStack.Wrike ||
                            testResults.techStack.Traction ||
                            testResults.techStack.TeamViewer ||
                            testResults.techStack.OpenAI ||
                            testResults.techStack.BrowserStack ||
                            testResults.techStack.InvisionApp ||
                            testResults.techStack.Parsec ||
                            testResults.techStack.Loaderio ||
                            testResults.techStack.Happeo ||
                            testResults.techStack.Tenable ||
                            testResults.techStack.Whimsical ||
                            testResults.techStack.GoCardless ||
                            testResults.techStack.MailChannels ||
                            testResults.techStack.Mimecast ||
                            testResults.techStack.ProofPoint
                          "
                        >
                          <div v-if="testResults.techStack.Google">
                            <!-- <img width="100px" src="../assets/logos/google.png" /> -->
                            <p class="lead text-center">Google</p>
                          </div>
                          <div v-if="testResults.techStack.Facebook">
                            <!-- <img width="100px" src="../assets/logos/facebook.png" /> -->
                            <p class="lead text-center">Facebook</p>
                          </div>
                          <div v-if="testResults.techStack.Microsoft">
                            <!-- <img width="100px" src="../assets/logos/microsoft.png" /> -->
                            <p class="lead text-center">Microsoft</p>
                          </div>
                          <div v-if="testResults.techStack.Amazon">
                            <!-- <img width="100px" src="../assets/logos/amazon.png" /> -->
                            <p class="lead text-center">Amazon</p>
                          </div>
                          <div v-if="testResults.techStack.Salesforce">
                            <!-- <img width="100px" src="../assets/logos/salesforce.png" /> -->
                            <p class="lead text-center">Salesforce</p>
                          </div>
                          <div v-if="testResults.techStack.Atlassian">
                            <!-- <img width="100px" src="../assets/logos/atlassian.png" /> -->
                            <p class="lead text-center">Atlassian</p>
                          </div>
                          <div v-if="testResults.techStack.Cloudflare">
                            <!-- <img width="100px" src="../assets/logos/cloudflare.png" /> -->
                            <p class="lead text-center">Cloudflare</p>
                          </div>
                          <div v-if="testResults.techStack.Adobe">
                            <!-- <img width="100px" src="../assets/logos/adobe.png" /> -->
                            <p class="lead text-center">Adobe</p>
                          </div>
                          <div v-if="testResults.techStack.DocuSign">
                            <!-- <img width="100px" src="../assets/logos/docusign.png" /> -->
                            <p class="lead text-center">DocuSign</p>
                          </div>
                          <div v-if="testResults.techStack.Mixpanel">
                            <!-- <img width="100px" src="../assets/logos/mixpanel.png" /> -->
                            <p class="lead text-center">Mixpanel</p>
                          </div>
                          <div v-if="testResults.techStack.OneTrust">
                            <!-- <img width="100px" src="../assets/logos/onetrust.png" /> -->
                            <p class="lead text-center">OneTrust</p>
                          </div>
                          <div v-if="testResults.techStack.Stripe">
                            <!-- <img width="100px" src="../assets/logos/stripe.png" /> -->
                            <p class="lead text-center">Stripe</p>
                          </div>
                          <div v-if="testResults.techStack.HubSpot">
                            <!-- <img width="100px" src="../assets/logos/hubspot.png" /> -->
                            <p class="lead text-center">HubSpot</p>
                          </div>
                          <div v-if="testResults.techStack.Twilio">
                            <!-- <img width="100px" src="../assets/logos/twilio.png" /> -->
                            <p class="lead text-center">Twilio</p>
                          </div>
                          <div v-if="testResults.techStack.Zendesk">
                            <!-- <img width="100px" src="../assets/logos/zendesk.png" /> -->
                            <p class="lead text-center">Zendesk</p>
                          </div>
                          <div v-if="testResults.techStack.SendGrid">
                            <!-- <img width="100px" src="../assets/logos/sendgrid.png" /> -->
                            <p class="lead text-center">SendGrid</p>
                          </div>
                          <div v-if="testResults.techStack.Mailgun">
                            <!-- <img width="100px" src="../assets/logos/mailgun.png" /> -->
                            <p class="lead text-center">Mailgun</p>
                          </div>
                          <div v-if="testResults.techStack.Pardot">
                            <!-- <img width="100px" src="../assets/logos/pardot.png" /> -->
                            <p class="lead text-center">Pardot</p>
                          </div>
                          <div v-if="testResults.techStack.Zoom">
                            <!-- <img width="100px" src="../assets/logos/zoom.png" /> -->
                            <p class="lead text-center">Zoom</p>
                          </div>
                          <div v-if="testResults.techStack.Smartsheet">
                            <!-- <img width="100px" src="../assets/logos/smartsheet.png" /> -->
                            <p class="lead text-center">Smartsheet</p>
                          </div>
                          <div v-if="testResults.techStack.Dropbox">
                            <!-- <img width="100px" src="../assets/logos/dropbox.png" /> -->
                            <p class="lead text-center">Dropbox</p>
                          </div>
                          <div v-if="testResults.techStack.Drift">
                            <!-- <img width="100px" src="../assets/logos/drift.png" /> -->
                            <p class="lead text-center">Drift</p>
                          </div>
                          <div v-if="testResults.techStack.Miro">
                            <!-- <img width="100px" src="../assets/logos/miro.png" /> -->
                            <p class="lead text-center">Miro</p>
                          </div>
                          <div v-if="testResults.techStack.Klaviyo">
                            <!-- <img width="100px" src="../assets/logos/klaviyo.png" /> -->
                            <p class="lead text-center">Klaviyo</p>
                          </div>
                          <div v-if="testResults.techStack.Box">
                            <!-- <img width="100px" src="../assets/logos/box.png" /> -->
                            <p class="lead text-center">Box</p>
                          </div>
                          <div v-if="testResults.techStack.Airtable">
                            <!-- <img width="100px" src="../assets/logos/airtable.png" /> -->
                            <p class="lead text-center">Airtable</p>
                          </div>
                          <div v-if="testResults.techStack.Auth0">
                            <!-- <img width="100px" src="../assets/logos/auth0.png" /> -->
                            <p class="lead text-center">Auth0</p>
                          </div>
                          <div v-if="testResults.techStack.Fastly">
                            <!-- <img width="100px" src="../assets/logos/fastly.png" /> -->
                            <p class="lead text-center">Fastly</p>
                          </div>
                          <div v-if="testResults.techStack.Segment">
                            <!-- <img width="100px" src="../assets/logos/segment.png" /> -->
                            <p class="lead text-center">Segment</p>
                          </div>
                          <div v-if="testResults.techStack.Notion">
                            <!-- <img width="100px" src="../assets/logos/notion.png" /> -->
                            <p class="lead text-center">Notion</p>
                          </div>
                          <div v-if="testResults.techStack.Slack">
                            <!-- <img width="100px" src="../assets/logos/slack.png" /> -->
                            <p class="lead text-center">Slack</p>
                          </div>
                          

                      </div>
                      </div>
                      </div>
                      </div>




                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header lead">
                          CMS Frameworks
                      </div>
                      <div class="card-body mx-auto" v-if="testResults.techStack.WordPress || testResults.techStack.Drupal || testResults.techStack.Joomla || testResults.techStack.Magento || testResults.techStack.Shopify || testResults.techStack.Sitecore || testResults.techStack.Squarespace || testResults.techStack.Wix || testResults.techStack.Typo3 || testResults.techStack.HubSpot">
                          <div v-if="testResults.techStack.WordPress"><img width="100px" src="../assets/logos/wordpress.png" /><p class="lead text-center">WordPress</p></div>
                          <div v-if="testResults.techStack.Drupal"><img width="100px" src="../assets/logos/drupal.png" /><p class="lead text-center">Drupal</p></div>
                          <div v-if="testResults.techStack.Joomla"><img width="100px" src="../assets/logos/joomla.png" /><p class="lead text-center">Joomla</p></div>
                          <div v-if="testResults.techStack.Magento"><img width="100px" src="../assets/logos/magento.png" /><p class="lead text-center">Magento</p></div>
                          <div v-if="testResults.techStack.Shopify"><img width="100px" src="../assets/logos/shopify.png" /><p class="lead text-center">Shopify</p></div>
                          <div v-if="testResults.techStack.Sitecore"><img width="100px" src="../assets/logos/sitecore.png" /><p class="lead text-center">Sitecore</p></div>
                          <div v-if="testResults.techStack.Squarespace"><img width="100px" src="../assets/logos/squarespace.png" /><p class="lead text-center">Squarespace</p></div>
                          <div v-if="testResults.techStack.Wix"><img width="100px" src="../assets/logos/wix.png" /><p class="lead text-center">Wix</p></div>
                          <div v-if="testResults.techStack.Typo3"><img width="100px" src="../assets/logos/typo3.png" /><p class="lead text-center">Typo3</p></div>
                          <div v-if="testResults.techStack.HubSpot"><img width="100px" src="../assets/logos/hubspot.png" /><p class="lead text-center">HubSpot</p></div>
                      </div>
                      <div v-else>
                          <p class="text-center"><i class="bi text-warning fs-1 bi-question-octagon-fill"></i></p>
                          <p class="lead text-center">No CMS Framework detected</p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header lead">
                        Javascript frameworks
                      </div>
                      <div class="card-body mx-auto" v-if="testResults.techStack.React || testResults.techStack.Angular || testResults.techStack.Vue || testResults.techStack.Svelte || testResults.techStack.Ember || testResults.techStack.Backbone || testResults.techStack.Meteor || testResults.techStack.Aurelia">
                        <div v-if="testResults.techStack.React"><img width="100px" src="../assets/logos/react.png" /><p class="lead text-center">ReactJS</p></div>
                        <div v-if="testResults.techStack.Angular"><img width="100px" src="../assets/logos/angular.png" /><p class="lead text-center">AngularJS</p></div>
                        <div v-if="testResults.techStack.Vue"><img width="100px" src="../assets/logos/vue.png" /><p class="lead text-center">VueJS</p></div>
                        <div v-if="testResults.techStack.Svelte"><img width="100px" src="../assets/logos/svelte.png" /><p class="lead text-center">SvelteJS</p></div>
                        <div v-if="testResults.techStack.Ember"><img width="100px" src="../assets/logos/ember-4c.svg" /><p class="lead text-center">EmberJS</p></div>
                        <div v-if="testResults.techStack.Backbone"><img width="100px" src="../assets/logos/backbone.png" /><p class="lead text-center">BackboneJS</p></div>
                        <div v-if="testResults.techStack.Meteor"><img width="100px" src="../assets/logos/meteor.png" /><p class="lead text-center">MeteorJS</p></div>
                        <div v-if="testResults.techStack.Aurelia"> <img width="100px" src="../assets/logos/aurelia.png" /><p class="lead text-center">AureliaJS</p></div>
                      </div>
                      <div v-else>
                        <p class="text-center"><i class="bi text-warning fs-1 bi-question-octagon-fill"></i></p>
                        <p class="lead text-center">No JS Framework detected</p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header lead">
                          CSS frameworks
                      </div>
                      <div class="card-body mx-auto" v-if="testResults.techStack.Bootstrap || testResults.techStack.Tailwind || testResults.techStack.Bulma || testResults.techStack.Foundation || testResults.techStack.Materialize">
                          <div v-if="testResults.techStack.Bootstrap"><img width="100px" src="../assets/logos/bootstrap.png" /><p class="lead text-center">Bootstrap</p></div>
                          <div v-if="testResults.techStack.Tailwind"><img width="100px" src="../assets/logos/tailwind.png" /><p class="lead text-center">Tailwind</p></div>
                          <div v-if="testResults.techStack.Bulma"><img width="100px" src="../assets/logos/bulma.png" /><p class="lead text-center">Bulma</p></div>
                          <div v-if="testResults.techStack.Foundation"><img width="100px" src="../assets/logos/foundation.png" /><p class="lead text-center">Foundation</p></div>
                          <div v-if="testResults.techStack.Materialize"><img width="100px" src="../assets/logos/materialize.png" /><p class="lead text-center">Materialize</p></div>
                      </div>
                      <div v-else>
                          <p class="text-center"><i class="bi text-warning fs-1 bi-question-octagon-fill"></i></p>
                          <p class="lead text-center">No CSS Framework detected</p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header lead">
                          Javascript Libraries
                      </div>
                      <div class="card-body mx-auto" v-if="testResults.techStack.jQuery || testResults.techStack.Lodash || testResults.techStack.D3 || testResults.techStack.Moment || testResults.techStack.Chart || testResults.techStack.Underscore || testResults.techStack.RxJS || testResults.techStack.Three || testResults.techStack.Anime || testResults.techStack.Axios">
                          <div v-if="testResults.techStack.jQuery"><img width="100px" src="../assets/logos/jquery.png" /><p class="lead text-center">jQuery</p></div>
                          <div v-if="testResults.techStack.Lodash"><img width="100px" src="../assets/logos/lodash.png" /><p class="lead text-center">Lodash</p></div>
                          <div v-if="testResults.techStack.D3"><img width="100px" src="../assets/logos/d3.png" /><p class="lead text-center">D3</p></div>
                          <div v-if="testResults.techStack.Moment"><img width="100px" src="../assets/logos/moment.png" /><p class="lead text-center">Moment</p></div>
                          <div v-if="testResults.techStack.Chart"><img width="100px" src="../assets/logos/chart.png" /><p class="lead text-center">Chart</p></div>
                          <div v-if="testResults.techStack.Underscore"><img width="100px" src="../assets/logos/underscore.png" /><p class="lead text-center">Underscore</p></div>
                          <div v-if="testResults.techStack.RxJS"><img width="100px" src="../assets/logos/rxjs.png" /><p class="lead text-center">RxJS</p></div>
                          <div v-if="testResults.techStack.Three"><img width="100px" src="../assets/logos/three.png" /><p class="lead text-center">Three</p></div>
                          <div v-if="testResults.techStack.Anime"><img width="100px" src="../assets/logos/anime.png" /><p class="lead text-center">Anime</p></div>
                          <div v-if="testResults.techStack.Axios"><img width="100px" src="../assets/logos/axios.png" /><p class="lead text-center">Axios</p></div>
                      </div>
                      <div v-else>
                          <p class="text-center"><i class="bi text-warning fs-1 bi-question-octagon-fill"></i></p>
                          <p class="lead text-center">No JavaScript Library detected</p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header lead">
                        Application Firewall
                      </div>
                      <div class="card-body mx-auto">
                        <div v-if="testResults.wafResult.wafName === 'ModSecurity'">
                          <img width="100px" src="../assets/logos/modsecurity.png" />
                          <p class="lead text-center">ModSecurity</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'BlockDoS.net'">
                          <img width="100px" src="../assets/logos/blockdos.png" /> 
                          <p class="lead text-center">BlockDoS.net</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'QRATOR'">
                          <img width="100px" src="../assets/logos/qrator.png" /> 
                          <p class="lead text-center">QRATOR</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Variti'">
                          <img width="100px" src="../assets/logos/variti.svg" /> 
                          <p class="lead text-center">Variti</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'CloudfloorDNS'">
                          <img width="100px" src="../assets/logos/cloudfloordns.png" /> 
                          <p class="lead text-center">CloudfloorDNS</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'AWS ELB'">
                          <img width="100px" src="../assets/logos/awselb.png" /> 
                          <p class="lead text-center">AWS ELB</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'DOSarrest'">
                          <img width="100px" src="../assets/logos/dosarrest.png" /> 
                          <p class="lead text-center">DOSarrest</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'ZScaler'">
                          <img width="100px" src="../assets/logos/zscaler.svg" /> 
                          <p class="lead text-center">ZScaler</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'YUNDUN'">
                          <!-- No logo -->
                          <p class="lead text-center">YUNDUN</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Safedog'">
                          <!-- <img width="100px" src="../assets/logos/safedog.png" />  -->
                          <p class="lead text-center">Safedog</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'HuaweiCloudWAF'">
                          <img width="100px" src="../assets/logos/huawei.png" /> 
                          <p class="lead text-center">HuaweiCloudWAF</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Beluga'">
                          <img width="100px" src="../assets/logos/beluga.png" /> 
                          <p class="lead text-center">Beluga</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Puhui'">
                          <!-- <img width="100px" src="../assets/logos/puhui.png" />  -->
                          <p class="lead text-center">Puhui</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'nginx-wallarm'">
                          <img width="100px" src="../assets/logos/wallarm.png" /> 
                          <p class="lead text-center">nginx-wallarm</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'F5-TrafficShield'">
                          <img width="100px" src="../assets/logos/f5.svg" /> 
                          <p class="lead text-center">F5-TrafficShield</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'EisooWAF'">
                          <!-- <img width="100px" src="../assets/logos/eisoo.png" />  -->
                          <p class="lead text-center">EisooWAF</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'EisooWAF-AZURE'">
                          <!-- <img width="100px" src="../assets/logos/eisooazure.png" />  -->
                          <p class="lead text-center">EisooWAF-AZURE</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Reblaze Secure Web Gateway'">
                          <img width="100px" src="../assets/logos/reblaze.png" /> 
                          <p class="lead text-center">Reblaze Secure Web Gateway</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Mission Control Application Shield'">
                          <!-- <img width="100px" src="../assets/logos/missioncontrol.png" />  -->
                          <p class="lead text-center">Mission Control Application Shield</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'NullDDoS'">
                          <!-- <img width="100px" src="../assets/logos/nullddos.png" />  -->
                          <p class="lead text-center">NullDDoS</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Squarespace'">
                          <!-- <img width="100px" src="../assets/logos/squarespace.png" />  -->
                          <p class="lead text-center">Squarespace</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'BDWAF'">
                          <!-- <img width="100px" src="../assets/logos/bdwaf.png" />  -->
                          <p class="lead text-center">BDWAF</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'KeyCDN'">
                          <!-- <img width="100px" src="../assets/logos/keycdn.png" />  -->
                          <p class="lead text-center">KeyCDN</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'AkamaiGHost'">
                          <!-- <img width="100px" src="../assets/logos/akamai.png" />  -->
                          <p class="lead text-center">AkamaiGHost</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Safe3 Web Firewall'">
                          <!-- <img width="100px" src="../assets/logos/safe3.png" />  -->
                          <p class="lead text-center">Safe3 Web Firewall</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'cloudflare'">
                          <!-- <img width="100px" src="../assets/logos/cloudflare.png" />  -->
                          <p class="lead text-center">cloudflare</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'cloudflare-nginx'">
                          <!-- <img width="100px" src="../assets/logos/cloudflarenginx.png" />  -->
                          <p class="lead text-center">cloudflare-nginx</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Newdefend'">
                          <!-- <img width="100px" src="../assets/logos/newdefend.png" />  -->
                          <p class="lead text-center">Newdefend</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'uewaf'">
                          <!-- <img width="100px" src="../assets/logos/uewaf.png" />  -->
                          <p class="lead text-center">uewaf</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'SonicWALL'">
                          <!-- <img width="100px" src="../assets/logos/sonicwall.png" />  -->
                          <p class="lead text-center">SonicWALL</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'ACE XML Gateway'">
                          <!-- <img width="100px" src="../assets/logos/acexmlgateway.png" />  -->
                          <p class="lead text-center">ACE XML Gateway</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'secking'">
                          <!-- <img width="100px" src="../assets/logos/secking.png" />  -->
                          <p class="lead text-center">secking</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'rwf-WAF'">
                          <!-- <img width="100px" src="../assets/logos/rwfwaf.png" />  -->
                          <p class="lead text-center">rwf-WAF</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'protected by webland'">
                          <!-- <img width="100px" src="../assets/logos/protectedbywebland.png" />  -->
                          <p class="lead text-center">protected by webland</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Yunjiasu'">
                          <!-- <img width="100px" src="../assets/logos/yunjiasu.png" />  -->
                          <p class="lead text-center">Yunjiasu</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'Airee'">
                          <!-- <img width="100px" src="../assets/logos/airee.png" />  -->
                          <p class="lead text-center">Airee</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'ECD'">
                          <!-- <img width="100px" src="../assets/logos/ecd.png" />  -->
                          <p class="lead text-center">ECD</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'ECS'">
                          <!-- <img width="100px" src="../assets/logos/ecs.png" />  -->
                          <p class="lead text-center">ECS</p>
                        </div>
                        <div v-if="testResults.wafResult.wafName === 'ServerDefender VP'">
                          <!-- <img width="100px" src="../assets/logos/serverdefendervp.png" />  -->
                          <p class="lead text-center">ServerDefender VP</p>
                        </div>
                        <div v-else>
                          <p class="text-center"><i class="bi text-warning fs-1 bi-question-octagon-fill"></i></p>
                          <p class="lead text-center">No Web Application Firewall detected</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="p-5 m-5 d-flex justify-content-center">
              <div class=" spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
</template>




<script>
export default {
  props: ['testResults'],
};
</script>

<style>
</style>