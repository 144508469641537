<template>
    <div mb-5>
      <HeaderBar />
      <div  v-if="company" class="container mt-4">
        <div class="card info_card">
          <div class="card-body">
            <div class="card logo_card">
              <img 
                    :src="company.companyAvatar" 
                    class="card-img-top card-img-bottom"  
                    :alt="company.companyName + ' Logo'" 
                    width="350px"
                    @error="handleError(index)"
                  />
            </div>
            <div class="row company_header">
              <div class="col">
                <h1>{{ company.companyLegal }}</h1>
                <p class="lead">
                {{ showMore ? company.companyAbstract : company.companyAbstract.substring(0, 400) }}
                <span
                  v-if="company.companyAbstract.length > 400"
                  role="button"
                  tabindex="0"
                  class="text-primary"
                  @click="toggleShowMore"
                >
                  {{ showMore ? "Show Less..." : "...Show More" }}
                </span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card mb-3 h-100">
                  <div class="card-body">
                    <h5 class="card-title">Company Information</h5>
                    <div class="card-text">
                      <div class="row">
                        <div class="col-sm-4 company_info_title">Location:</div>
                        <div class="col">{{ company.companyCity }}, {{ company.companyCountry }}</div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4 company_info_title">Website:</div>
                        <div class="col">
                          <a :href="company.companyHomepage" target="_blank" rel="noreferrer">
                            {{ company.companyHomepage }}
                          </a>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-sm-4 company_info_title">Est Budget (Rev):</div>
                      <div v-if="company.companyRevenue" class="col">
                        {{ revenueBudget(company.companyRevenue) }}
                      </div>
                      <div v-else class="col">Revenue Unknown</div>
                    </div>
                      <div class="row">
                        <div class="col-sm-4 company_info_title">Est Budget (Emp):</div>
                        <div  v-if="company.companyEmployees" class="col">
                          {{ employeeBudget(company.companyEmployees) }} 
                        </div>
                        <div  v-else class="col">
                         Employee count unknown 
                        </div>
                      </div>
                      
                      
                      <div class="row">
                        <div class="col-sm-4 company_info_title">Imported:</div>
                        <div class="col">{{ formatDate(company.createdAt.seconds) }}</div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4 company_info_title">Last Tested:</div>
                        <div class="col">{{ formatDate(company.lastUpdated.seconds) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-2 h-100">
                  <div class="card-body">
                    <h5 class="card-title">Company Resillience Score</h5>
                    <div class="card-text">
                     
                     
                      <div v-if="company.CRS" class="col">
                        {{ company.CRS }}
                      </div>
                      <div v-else class="col text-large"><i class="text-secondary bi bi-question-circle"></i></div>
                    
                     
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <brandResults :testResults="testResults" />
        <emailResults :testResults="testResults" />
        <webResults :testResults="testResults" />
        <techStackResults :testResults="testResults" />
  
  
  
  
      </div>
      <div v-else class="p-5 m-5 d-flex justify-content-center">
            <div class=" spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    </div>
    <FooterBar />
  </template>
  
  <script>
  import dayjs from 'dayjs';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import FooterBar from '../components/FooterBar';
import HeaderBar from '../components/HeaderBar';
import brandResults from '../components/brandResults.vue';
import emailResults from '../components/emailResults.vue';
import techStackResults from '../components/techStackResults.vue';
import webResults from '../components/webResults.vue';
import { db, doc, getDoc } from '../firebase'; // Update to correct path
  
  export default {
    name: 'CompanyDetails',
    components: {
      HeaderBar,
      FooterBar,
      emailResults,
      webResults,
      brandResults,
      techStackResults
    },
    setup() {
      const route = useRoute();
      const company = ref(null);
      const testResults =ref(null);
      const companyDomain = route.params.companyDomain;

      const fetchCompany = async () => {
        try {
          const docRef = doc(db, 'companies', companyDomain);
          const docSnap = await getDoc(docRef);
  
          if (docSnap.exists()) {
            company.value = docSnap.data();
          } else {
            console.error('No such company document!');
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      };

      const fetchTestResults = async () => {
        try {
                const testRef = doc(db, 'foreshadowTests', company.value.lastTestRef);
                const testSnap = await getDoc(testRef);

                if (testSnap.exists()) {
                    testResults.value = testSnap.data();
                } else {
                    console.error('No such test document!');
                }
    

                } catch (error) {
                    console.error('Error fetching test results:', error);
                }
            }
      

 
      const showMore = ref(false);

        const toggleShowMore = () => {
        showMore.value = !showMore.value;
        console.log('hit show more')
        };

      const handleError = () => {
      company.value.companyAvatar = company.value.companyLogo;
      // Force the image to reload by changing its key
      const imgElement = document.querySelectorAll('.card-img-top');
      if (imgElement) {
        imgElement.src = company.value.companyLogo;
      }
    };

    const employeeBudget = (value) => {
      const minEmpBudget = 1178 * value
      const maxEmpBudget = 2691 * value;
      const employeeCalc = '$' + minEmpBudget.toLocaleString('en-AU') + ' - $' + maxEmpBudget.toLocaleString('en-AU')
      return employeeCalc;
    };
    const revenueBudget = (revValue) => {
      const minRevBudget = 0.002 * revValue;
      const maxRevBudget = 0.008 * revValue;
      const revenueCalc = '$' + minRevBudget.toLocaleString('en-AU', {maximumFractionDigits: 0}) + ' - $' + maxRevBudget.toLocaleString('en-AU', {maximumFractionDigits: 0})
      return revenueCalc;
    };

    const formatDate = (date) => {
      return dayjs.unix(date).format('D MMMM YYYY');
    };

    watch(company, (newCompany) => {
      if (newCompany && newCompany.lastTestRef) {
        fetchTestResults(newCompany.lastTestRef);
      }
    }, { immediate: true });
  
      onMounted(fetchCompany);
  
      return { 
        company,
        testResults,
        handleError, 
        toggleShowMore, 
        showMore, 
        formatDate,
        employeeBudget,
        revenueBudget
        };
    }
  };
  </script>
  
  
  <style scoped>

  .card-img-top {
    object-fit: cover;
    height: 150px;
  }
  .company_info_title {
    font-weight: bold;
  }
  .text-large {
  font-size:550%;
  text-align: center;
}
  </style>
  
  
  