<template>
  <HeaderBar></HeaderBar>
  <div class="container">
    <div class="card my-5">
      <div class="card-body">
        <div class="container">
          <h1>Foreshadow Administration</h1>

          <div
            v-if="alert"
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            {{ alert }}
            <button type="button" class="btn-close" @click="closeAlert"></button>
          </div>

          <form @submit.prevent="handleSubmit">
            <div class="row mt-4">
              <div class="col-xs-10 col-10">
                <input
                  type="text"
                  class="form-control"
                  v-model="domain"
                  required
                  placeholder="Enter company domain"
                  @input="validateDomain"
                />
                <div v-if="domainError" class="text-danger">
                  {{ domainError }}
                </div>
              </div>
              <div class="col-xs-2 col-2">
                <button type="submit" class="btn btn-warning w-100" :disabled="!isValidDomain">
                  Add Company
                </button>
              </div>
            </div>
          </form>
          
        </div>
        <div class="mt-3">
        <ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link active" aria-current="page" href="#">Companies</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Users</a>
  </li>
  
</ul>
</div>
<div>
  <table class="table">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">Company</th>
      <th scope="col">Status</th>
      <th scope="col">Date added</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(company, index) in companies" :key="index">
           <th scope="row" class="align-middle">
             <i
               class="text-success bi bi-check-circle-fill"
               v-if="company.companyStatusCode === 'success'"
             ></i>
             <i
               class="text-danger bi bi-x-circle"
               v-else
             ></i>
           </th>
           <td v-if="company.companyName" class="align-middle">{{ company.companyName }}</td>
           <td v-else  class="align-middle">{{ company.companyDomain }}</td>
           <td class="align-middle"><p v-if="company.createdAt" class="align-middle">{{ company.companyStatusText }}</p></td>
           <td class="align-middle"><p v-if="company.createdAt" class="align-middle">{{ formatDate(company.createdAt) }}</p></td>
           <td class="float-right">
            <div class="btn-group">
                <button class="btn btn-link nav-link py-2 px-0 px-lg-2 d-flex align-items-center" type="button" aria-expanded="false" data-bs-toggle="dropdown">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
              
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li class="nav-item">
                            <RouterLink class="dropdown-item" aria-current="page" to="#">Run new test</RouterLink>
                        </li>
                        <li class="nav-item">
                            <RouterLink class="dropdown-item" aria-current="page" to="#">Something else</RouterLink>
                        </li>
                        
                        
                    </ul>
                </div>

          
          
          </td>
         </tr>
  </tbody>
</table>
</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { onMounted, onUnmounted, ref } from "vue";
import HeaderBar from "../components/HeaderBar";
import { collection, db, doc, getDocs, onSnapshot, serverTimestamp, setDoc } from "../firebase"; // Ensure firebase is configured

export default {
  name: "AppAdmin",
  components: {
    HeaderBar,
  },
  setup() {
    const domain = ref("");
    const alert = ref(null);
    const isValidDomain = ref(false);
    const domainError = ref(null);
    const companies = ref([]); 

    const validateDomain = () => {
      const regex =
        /^(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?\.)+[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62}$/i;
      isValidDomain.value = regex.test(domain.value);
      if (!isValidDomain.value) {
        domainError.value = "Please enter a valid domain name";
      } else {
        domainError.value = null;
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        // Sanitize input (basic example - consider more robust sanitization)
        const sanitizedDomain = domain.value.trim().toLowerCase();
        // Use setDoc with doc to explicitly set the document ID
        await setDoc(doc(db, "companies", sanitizedDomain), {
          companyDomain: sanitizedDomain,
          showRecord: false,
          createdAt: serverTimestamp(),
        });
        domain.value = ""; // Reset form value
        alert.value = "A new company has been added to the database.";
        console.log("Company added successfully");
      } catch (error) {
        console.error("Error adding company: ", error);
      }
    };

    const closeAlert = () => {
      alert.value = false;
    };
    const formatDate = (date) => {
      const unixTimestamp = date.seconds;
      return dayjs.unix(unixTimestamp).format('D MMMM YYYY');
    };
    
    // Function to fetch initial companies on mount
    const fetchCompanies = async () => {
      const querySnapshot = await getDocs(collection(db, "companies"));
      companies.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    };

    // Realtime listener for changes in the 'companies' collection
    const unsubscribe = onSnapshot(collection(db, "companies"), (snapshot) => {
      companies.value = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });

    onMounted(async () => {
      await fetchCompanies(); // Get initial data
    });

    // Unsubscribe from the listener when the component is unmounted
    onUnmounted(() => {
      unsubscribe();
    });

return {
    domain,
    alert,
    handleSubmit,
    closeAlert,
    isValidDomain,
    domainError,
    validateDomain,
    companies,
    formatDate
  };
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}
</style>