<template>
    <div class="input-group my-5 input-group-lg">
     
      <input 
        type="text" 
        class="form-control border-left-0" 
        v-model="searchQuery" 
        placeholder="Search company..." 
        aria-label="Search company" 
        aria-describedby="search-button"
      >
      <!-- <button class="btn btn-warning" type="button" id="search-button">Search</button> -->
    </div>
  
    <div class="card my-5">
      <div class="card-body">
        <div class="container">
          <div v-if="filteredCompanies.length > 0" class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
            <div v-for="(company, index) in filteredCompanies" :key="company.id" class="col">
              <RouterLink 
                :to="{ name: 'CompanyDetails', params: { companyDomain: company.companyDomain } }" 
                class="link-underline-danger link-underline-opacity-0"
              >
                <div class="card m-2">
                  <img 
                    :src="company.companyAvatar" 
                    class="card-img-top"  
                    :alt="company.companyName + ' Logo'" 
                    @error="handleError(index)"
                  />
                  <div class="card-footer">
                    <p class="card-text text-truncate link-underline link-underline-opacity-0">{{ company.companyName }}</p>
                  </div>
                </div>
              </RouterLink>
            </div>
          </div>
          <div v-else-if="companies.length > 0" class="p-5 m-5 d-flex justify-content-center">
            <p>No matching companies found.</p>
          </div>
          <div v-else class="p-5 m-5 d-flex justify-content-center">
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { computed, onMounted, ref } from "vue";
import { db } from '../firebase';
  
  export default {
    name: 'SearchCompany',
    setup() {
      const companies = ref([]);
      const searchQuery = ref('');
  
      const fetchCompanies = async () => {
        const q = query(collection(db, 'companies'), where('showRecord', '==', true), orderBy('companyName'));
        const querySnapshot = await getDocs(q);
        companies.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      };
  
      const handleError = (index) => {
        companies.value[index].companyAvatar = companies.value[index].companyLogo || 'https://example.com/default-profile.png';
        const imgElement = document.querySelectorAll('.card-img-top')[index];
        if (imgElement) {
          imgElement.src = companies.value[index].companyLogo || 'https://example.com/default-profile.png';
        }
      };
  
      const filteredCompanies = computed(() => {
        if (!searchQuery.value) {
          return companies.value;
        }
        const lowercaseQuery = searchQuery.value.toLowerCase();
        return companies.value.filter(company => 
          company.companyName.toLowerCase().includes(lowercaseQuery)
        );
      });
  
      onMounted(fetchCompanies);
  
      return { 
        companies, 
        searchQuery, 
        handleError, 
        filteredCompanies 
      };
    }
  };
  </script>
  
  <style>
  .logo_card {
    width: 128px;
    height: 128px;
    margin-top: -100px !important;
    margin: auto;
  }
  .info_card {
    margin-top: 100px;
  }
  
  span.input-group-text {
      background-color: #fff;
  
  }
  /* .form-control:focus {
  border-color: #FFF;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 8px #393b3d;
} */
  </style>