// src/router/index.js
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { createRouter, createWebHistory } from 'vue-router';
import Account from '../views/AccountDetails.vue';
import AppAdmin from '../views/AppAdmin.vue';
import CompanyDetails from '../views/CompanyDetails.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import Home from '../views/HomeContainer.vue';
import Login from '../views/LoginView.vue';
import Signup from '../views/SignupView.vue';

const routes = [
    //  Pages that require authentication
    { path: '/', name: 'Home', component: Home, meta: { requiresAuth: true } },
    {
        path: '/company/:companyDomain',
        name: 'CompanyDetails',
        component: CompanyDetails,
        meta: { requiresAuth: true }
    },
    { path: '/admin', name: 'AppAdmin', component: AppAdmin, meta: { requiresAuth: true } },
    { path: '/account', name: 'Account', component: Account, meta: { requiresAuth: true } },
    // All pages below do not require auth
    { path: '/login', name: 'Login', component: Login },
    { path: '/signup', name: 'Signup', component: Signup },
    { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// Navigation guard to protect routes
router.beforeEach((to, from, next) => {
    const auth = getAuth();
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth) {
        onAuthStateChanged(auth, user => {
            if (user) {
                next();
            } else {
                next('/login');
            }
        });
    } else {
        next();
    }
});

export default router;



