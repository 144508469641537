<template>
    
  <div class="my-5">
      <div class="card">
        <div class="card-body">
          <div v-if="testResults">
          <div class="row">
              <div class="col">   
                  <h4 class="card-title">Web Security</h4>
              </div>
              <div class="col">
                  <h3 class="float-end">
                      <span class="badge bg-secondary">--%</span>
                  </h3>
              </div>
          </div>
          
          <div class="row px-3">
              <table class="table ">
                  <tbody>
                     <!-- HTTP Redirect -->
                  <tr>
                        <th scope="row">
                            <i v-if="testResults.redirectResult.isRedirected == true" class="h3 text-success bi bi-check-circle-fill"></i>
                            <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                        </th>
                        <td class="lead">HTTP redirects to HTTPS</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#redirectTest1" role="button" aria-expanded="false" aria-controls="redirectTest1"></i>
                        </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="redirectTest1">
                            <td colspan="3">   
                                <p class="blockquote-footer mt-2">{{ testResults.redirectResult }}</p> 
                                <p class="text-muted ms-3">
                                    HTTP redirects to HTTPS is a security practice that automatically directs users from an unsecured HTTP connection to a secured HTTPS connection when they visit your website. This ensures that all data transmitted between the user's browser and your website is encrypted, protecting it from interception and tampering by malicious actors. Implementing HTTP to HTTPS redirects enhances data security, protects sensitive information, boosts user trust, and can improve your site's search engine ranking. This practice is essential for maintaining a secure and trustworthy online presence.
                                </p>
                            </td>
                        </tr>
                  <!-- TLS Results -->
                   <!-- TLS is in use -->
                  <tr>
                      <th scope="row">
                          <i v-if="testResults.allTLSChecks.TLSv1 == true || testResults.allTLSChecks['TLSv1.1'] == true || testResults.allTLSChecks['TLSv1.2'] == true || testResults.allTLSChecks['TLSv1.3'] == true" class="h3 text-success bi bi-check-circle-fill"></i>
                          <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                          
                      </th>
                      <td class="lead">Transport Layer Security (TLS) available</td>
                      <td>
                          <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#tlsTest" role="button" aria-expanded="false" aria-controls="tlsTest"></i>
                      </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="tlsTest">
                      <td colspan="3">
                          <p class="blockquote-footer mt-2">{{ testResults.allTLSChecks }}</p>   
                          <p class="text-muted ms-3">
                            Using TLS (Transport Layer Security) on your website is essential for ensuring the security and privacy of data exchanged between your users and your server. TLS encrypts this data, protecting it from interception and tampering by malicious actors. This not only safeguards sensitive information, such as login credentials and payment details, but also enhances your site's credibility and trustworthiness. Furthermore, using TLS can improve your search engine ranking and is often a requirement for compliance with data protection regulations. Overall, implementing TLS is a fundamental step in providing a secure and trustworthy online experience for your users.
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <th scope="row">
                          <i v-if="testResults.allTLSChecks.TLSv1 == false && testResults.allTLSChecks['TLSv1.1'] == false" class="h3 text-success bi bi-check-circle-fill"></i>
                          <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                          
                      </th>
                      <td class="lead">Insecure versions of TLS not in use</td>
                      <td>
                          <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#tls1Test" role="button" aria-expanded="false" aria-controls="tls1Test"></i>
                      </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="tls1Test">
                      <td colspan="3">
                          <p class="blockquote-footer mt-2">{{ testResults.allTLSChecks }}</p>   
                          <p class="text-muted ms-3">
                            Using an insecure version of TLS (Transport Layer Security) is detrimental to your system's security. Older versions of TLS, such as TLS 1.0 and TLS 1.1, contain known vulnerabilities that can be exploited by attackers to intercept and manipulate data. This compromises the confidentiality and integrity of sensitive information transmitted between servers and clients. Relying on outdated TLS versions increases the risk of data breaches, weakens your security posture, and can lead to a loss of trust among users. Ensuring the use of the latest TLS versions, such as TLS 1.2 or TLS 1.3, is crucial for maintaining robust data security and protecting against cyber threats.
                          </p>
                      </td>
                  </tr>
                  <!-- TLS 1.3 -->
                  <tr>
                      <th scope="row">
                          <i v-if="testResults.allTLSChecks['TLSv1.3'] == true" class="h3 text-success bi bi-check-circle-fill"></i>
                          <i v-if="testResults.allTLSChecks['TLSv1.3'] == false" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                      </th>
                      <td class="lead">Version 1.3 of TLS available</td>
                      <td>
                          <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#tlsTest2" role="button" aria-expanded="false" aria-controls="tlsTest2"></i>
                      </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="tlsTest2">
                      <td colspan="3">
                          <p class="blockquote-footer mt-2">Result pending...</p>   
                          <p class="text-muted ms-3">
                            Enabling TLS version 1.3 is crucial for enhancing your system's security and performance. TLS 1.3 offers significant improvements over previous versions, including stronger encryption algorithms, reduced handshake latency, and the elimination of outdated and insecure cryptographic protocols. By adopting TLS 1.3, you ensure better protection against cyber threats, faster and more efficient data transmission, and compliance with current security standards. This upgrade enhances the confidentiality, integrity, and overall reliability of your communications, safeguarding sensitive information and bolstering user trust.
                          </p>
                      </td>
                  </tr>
                   <!-- TLS Expiry -->
                   <tr>
                        <th scope="row">
                            <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                        </th>
                        <td class="lead">TLS expiry less than 20 days</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#redirectTest1" role="button" aria-expanded="false" aria-controls="redirectTest1"></i>
                        </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="redirectTest1">
                            <td colspan="3">   
                                <p class="blockquote-footer mt-2">Test not carried out.</p> 
                                <p class="text-muted ms-3">
                                    Allowing your TLS certificate to get close to expiration risks service interruptions, security warnings for users, and potential vulnerability to attacks. Keeping your TLS certificate renewed well in advance helps ensure continuous encryption of data, maintains user trust, and upholds the credibility of your website. Proactively managing your TLS certificates enhances overall site security and reliability.
                                </p>
                            </td>
                        </tr>
                        <!-- TLS Expiry -->
                  <tr>
                        <th scope="row">
                            <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                        </th>
                        <td class="lead">TLS expiry greater than 398 days</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#redirectTest1" role="button" aria-expanded="false" aria-controls="redirectTest1"></i>
                        </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="redirectTest1">
                            <td colspan="3">   
                                <p class="blockquote-footer mt-2">Test not carried out.</p> 
                                <p class="text-muted ms-3">
                                    Ensuring that your TLS certificate is not valid for more than 398 days is important for maintaining optimal security standards. Certificates with shorter lifespans require more frequent renewal, which ensures that encryption standards and security practices are up to date. This reduces the risk of vulnerabilities and exploits associated with older, long-term certificates. Adhering to this guideline helps keep your website secure, ensures compliance with industry best practices, and enhances user trust by demonstrating a commitment to maintaining a secure online environment.
                                </p>
                            </td>
                        </tr>
                  <!-- TLS 1.2 -->
                  <tr>
                  <th scope="row">
                      <i v-if="testResults.wafResult.detected == true" class="h3 text-success bi bi-check-circle-fill"></i>
                      <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                  </th>
                  <td class="lead">Webserver behind a Web Application Firewall (WAF)</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#WAFTest1" role="button" aria-expanded="false" aria-controls="WAFTest1"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="WAFTest1">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.wafResult }}</p> 
                          <p class="text-muted ms-3">
                            A Web Application Firewall (WAF) is a security solution designed to protect web applications by filtering and monitoring HTTP traffic between a web application and the internet. A WAF helps defend against common web exploits and attacks, such as SQL injection, cross-site scripting (XSS), and distributed denial-of-service (DDoS) attacks. By analysing incoming traffic and blocking malicious requests, a WAF enhances your website's security, protects sensitive data, and ensures the availability and integrity of your web applications. Implementing a WAF in front of your website is essential for mitigating security risks, complying with industry standards, and maintaining user trust.
                          </p>
                      </td>
                  </tr>
                  <!-- TLS 1.3 -->
                  <tr v-if="testResults.securityHeaders">
                  <th scope="row">
                    <i v-if="testResults.securityHeaders['Strict-Transport-Security'] == 'Header not found'" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                      <i v-else class="h3 text-success bi bi-check-circle-fill"></i>
                     
                  </th>
                  <td class="lead">HTTP Strict Transport Security (HSTS) enabled </td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#hstsTest1" role="button" aria-expanded="false" aria-controls="hstsTest1"></i>
                  </td>
                  </tr>
                  <tr v-if="testResults.securityHeaders" class="collapse multi-collapse" id="hstsTest1">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['Strict-Transport-Security'] }}</p> 
                          <p class="text-muted ms-3">
                            HTTP Strict Transport Security (HSTS) is a security policy mechanism that helps protect websites by ensuring that browsers only interact with them over secure HTTPS connections. By enabling HSTS, you force browsers to use HTTPS for all communication with your site, preventing man-in-the-middle attacks and cookie hijacking. This enhances the security of your website by ensuring data integrity and confidentiality, improves user trust, and helps maintain compliance with security standards. Implementing HSTS is a proactive step in safeguarding your website and its users.
                          </p>
                      </td>
                  </tr>
                  <!-- HTST Max Age -->
                  <tr>
                  <th scope="row">
                    <i v-if="testResults.securityHeaders.hstsTest == 'pass'" class="h3 text-success bi bi-check-circle-fill"></i>
                    <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                  </th>
                  <td class="lead">Acceptable max age for HSTS configuration</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#hstsTest2" role="button" aria-expanded="false" aria-controls="hstsTest2"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="hstsTest2">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['Strict-Transport-Security'] }}</p> 
                          <p class="text-muted ms-3">
                            Implementing HTTP Strict Transport Security (HSTS) on your organisation's website is essential for ensuring that all interactions are conducted over secure HTTPS connections, thereby protecting against man-in-the-middle attacks and ensuring data integrity and confidentiality. HSTS enforces the use of HTTPS by instructing browsers to automatically switch from HTTP to HTTPS and refuse any insecure connections.
                          </p>
                          <p class="text-muted ms-3">  
                            An acceptable max age for HSTS is typically set to at least 6 months (15768000 seconds) to ensure long-term enforcement of HTTPS, but a max age of 1 year (31536000 seconds) or more is often recommended for optimal security. This duration helps maintain a robust and consistent security policy, ensuring users' data remains protected over time.
                          </p>
                      </td>
                  </tr>
                   <!-- HTST Subdomain -->
                   <tr>
                  <th scope="row">
                    <i v-if="testResults.securityHeaders['Strict-Transport-Security'].includes('includeSubDomains')" class="h3 text-success bi bi-check-circle-fill"></i>
                    <i v-else class="h3 text-danger bi bi-exclamation-circle-fill "></i>
                  </th>
                  <td class="lead">Subdomains included within HSTS configuration</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#hstsTest3" role="button" aria-expanded="false" aria-controls="hstsTest3"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="hstsTest3">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['Strict-Transport-Security'] }}</p> 
                          <p class="text-muted ms-3">
                            Including subdomains in your Strict Transport Security (HSTS) settings is crucial for ensuring comprehensive security across your entire domain. By applying HSTS to all subdomains, you ensure that every subdomain, not just the main domain, enforces HTTPS connections. This helps prevent potential security gaps where a subdomain could be targeted by attackers using insecure HTTP connections. Implementing HSTS with the "includeSubDomains" directive provides consistent protection, enhances data integrity and confidentiality across your entire web presence, and strengthens overall user trust in your organisation's commitment to security.
                          </p>
                      </td>
                  </tr>
                  <!-- HTST Preload Header -->
                  <tr>
                  <th scope="row">
                    <i v-if="testResults.securityHeaders['Strict-Transport-Security'].includes('preload')" class="h3 text-success bi bi-check-circle-fill"></i>
                    <i v-else class="h3 text-danger bi bi-exclamation-circle-fill "></i>
                  </th>
                  <td class="lead">HSTS header configured with preload</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#hstsTest4" role="button" aria-expanded="false" aria-controls="hstsTest4"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="hstsTest4">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['Strict-Transport-Security'] }}</p> 
                          <p class="text-muted ms-3">
                            Configuring the HSTS (HTTP Strict Transport Security) header with the preload directive on your company's website is a proactive security measure that ensures your site and all its subdomains are always accessed securely via HTTPS. When a site is preloaded, it's added to a list used by browsers to automatically enforce HTTPS from the first visit, even before the first HTTP request is made. This eliminates the risk of users initially connecting via an insecure HTTP connection, further protecting against man-in-the-middle attacks. Implementing HSTS with preload enhances security, boosts user trust, and demonstrates a strong commitment to safeguarding data.
                          </p>
                      </td>
                  </tr>
                   <!-- HTST Preload Header -->
                   <tr>
                  <th scope="row">
                    <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                  </th>
                  <td class="lead">Domain within HSTS preload list</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#contentsecurityTest2" role="button" aria-expanded="false" aria-controls="contentsecurityTest2"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="contentsecurityTest2">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">Test not carried out.</p> 
                          <p class="text-muted ms-3">
                              HSTS blurb.......
                          </p>
                      </td>
                  </tr>
                  <!-- Content Security Policy enabled -->
                  <tr>
                  <th scope="row">
                    <i v-if="testResults.securityHeaders['Content-Security-Policy'] == 'Header not found'" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                    <i v-else class="h3 text-success bi bi-check-circle-fill"></i>
                  </th>
                  <td class="lead">Content Security Policy header found</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#contentsecurityTest1" role="button" aria-expanded="false" aria-controls="contentsecurityTest1"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="contentsecurityTest1">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['Content-Security-Policy'] }}</p> 
                          <p class="text-muted ms-3">
                            Content Security Policy (CSP) is a security feature that helps protect websites from various types of attacks, such as cross-site scripting (XSS) and data injection attacks. CSP allows you to specify which content sources are trusted, thereby controlling which scripts, styles, and other resources can be executed or loaded on your site. By implementing CSP, you can prevent the execution of malicious code, reduce the risk of data breaches, and enhance the overall security of your website. This proactive measure helps protect your users, maintains the integrity of your site, and fosters trust in your online presence.
                          </p>
                      </td>
                  </tr>
                  <!-- Content Security Policy Configuration -->
                  <tr>
                  <th scope="row">
                    <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                  </th>
                  <td class="lead">Content Security Policy configured</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#contentsecurityTest2" role="button" aria-expanded="false" aria-controls="contentsecurityTest2"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="contentsecurityTest2">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">Test not carried out.</p> 
                          <p class="text-muted ms-3">
                              Content Security blurb.......
                          </p>
                      </td>
                  </tr>
                   <!-- HttpOnly Cookies -->
                   <tr>
                  <th scope="row">
                    <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                  </th>
                  <td class="lead">Domain has cookies set to HttpOnly</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#contentsecurityTest2" role="button" aria-expanded="false" aria-controls="contentsecurityTest2"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="contentsecurityTest2">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">Test not carried out.</p> 
                          <p class="text-muted ms-3">
                            HttpOnly Cookies blurb.......
                          </p>
                      </td>
                  </tr>
                  <!-- Secure Cookies -->
                  <tr>
                  <th scope="row">
                    <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                  </th>
                  <td class="lead">Domain has secure flag on all cookies</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#contentsecurityTest2" role="button" aria-expanded="false" aria-controls="contentsecurityTest2"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="contentsecurityTest2">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">Test not carried out.</p> 
                          <p class="text-muted ms-3">
                              Secure Cookie blurb.......
                          </p>
                      </td>
                  </tr>
                 <!-- X-Powered-By -->
                 <tr>
                  <th scope="row">
                    <i v-if="testResults.securityHeaders['X-Powered-By'] == 'Header not found'" class="h3 text-success bi bi-check-circle-fill"></i>
                    <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                  </th>
                  <td class="lead">X-Powered-By header is not set</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#xpoweredtest1" role="button" aria-expanded="false" aria-controls="xpoweredtest1"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="xpoweredtest1">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['X-Powered-By'] }}</p> 
                          <p class="text-muted ms-3">
                            Avoiding the use of the X-Powered-By header on your organisation's website is a good security practice because it reduces the amount of information exposed to potential attackers. This header often reveals the technology stack or software version running on your server, which can be used to identify and exploit specific vulnerabilities. By omitting the X-Powered-By header, you make it more difficult for attackers to target your site based on known issues, thereby enhancing your overall security posture and protecting sensitive data.
                          </p>
                      </td>
                  </tr>
                   <!-- X-Content-Type-Options -->
                 <tr>
                  <th scope="row">
                    <i v-if="testResults.securityHeaders['X-Content-Type-Options'] == 'nosniff'" class="h3 text-success bi bi-check-circle-fill"></i>
                    <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                  </th>
                  <td class="lead">X-Content-Type-Options header set to 'nosniff'</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#xcontenttypetest1" role="button" aria-expanded="false" aria-controls="xcontenttypetest1"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="xcontenttypetest1">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['X-Content-Type-Options'] }}</p> 
                          <p class="text-muted ms-3">
                            Setting the X-Content-Type-Options header to "nosniff" on your organisation's website is important for preventing browsers from interpreting files as a different MIME type than what is specified. This helps mitigate the risk of certain types of attacks, such as drive-by downloads or script execution vulnerabilities. By enforcing the correct MIME type, this header ensures that content is handled in a secure and expected manner, enhancing overall site security and protecting users from potentially harmful content.
                          </p>
                      </td>
                  </tr>
                  <!-- X-Frame-Options -->
                 <tr>
                  <th scope="row">
                    
                    <i v-if="testResults.securityHeaders['X-Frame-Options'] === 'SAMEORIGIN' || 'deny'" class="h3 text-success bi bi-check-circle-fill"></i>
                    <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                  </th>
                  <td class="lead">X-Frame-Options header set to 'deny' or 'sameorigin'</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#xframetest1" role="button" aria-expanded="false" aria-controls="xframetest1"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="xframetest1">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['X-Frame-Options'] }}</p> 
                         
                          <p class="text-muted ms-3">
                            Setting the X-Frame-Options header to 'deny' or 'sameorigin' on your organisation's website is crucial for preventing clickjacking attacks. Clickjacking occurs when an attacker embeds your website within a malicious frame, tricking users into interacting with content they can't see, which can lead to unintended actions or data theft. By using 'deny', you completely block your site from being framed by any other site. Using 'sameorigin' allows framing only by your own domain. Implementing this header enhances your website's security, protects user interactions, and maintains the integrity of your web content.
                          </p>
                      </td>
                  </tr>
                   <!-- X-XSS-Protection -->
                 <tr>
                  <th scope="row">
                    
                    <i v-if="testResults.securityHeaders['X-XSS-Protection'] == 'Header not found'" class="h3 text-success bi bi-check-circle-fill"></i>
                    <i v-else class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                  </th>
                  <td class="lead">Legacy X-XSS-Protection header not in use</td>
                  <td>
                      <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#xsstest1" role="button" aria-expanded="false" aria-controls="xsstest1"></i>
                  </td>
                  </tr>
                  <tr class="collapse multi-collapse" id="xsstest1">
                      <td colspan="3">   
                          <p class="blockquote-footer mt-2">{{ testResults.securityHeaders['X-XSS-Protection'] }}</p> 
                         
                          <p class="text-muted ms-3">
                            A company should avoid using the X-XSS-Protection header because it is an outdated security measure that can be unreliable and, in some cases, even introduce security risks. Modern browsers may no longer support or fully rely on this header. Instead, using a Content Security Policy (CSP) is a more robust and effective approach to preventing cross-site scripting (XSS) attacks. CSP allows you to specify trusted sources for content, significantly reducing the risk of malicious scripts being executed. By relying on CSP, you enhance your website's security, ensuring better protection against XSS attacks and other vulnerabilities.
                          </p>
                      </td>
                  </tr>









                  </tbody>
              </table>
          </div> 
      </div>
      <div v-else class="p-5 m-5 d-flex justify-content-center">
          <div class=" spinner-border text-warning" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
    </div>   
  </div>
</div>
</div>


</template>




<script>
export default {
props: ['testResults'],
};
</script>

<style>
</style>