<template>
    <HeaderBar />
    <div class="container">
        <SearchBar />
      
    </div>
    <FooterBar></FooterBar>
  </template>
  
  
  <script>
  import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { onMounted, ref } from 'vue';
import FooterBar from '../components/FooterBar';
import HeaderBar from '../components/HeaderBar';
import SearchBar from '../components/SearchBar';
import { db } from '../firebase';
    
    export default {
      name: 'SearchCompany',
      components: {
        HeaderBar,
        FooterBar,
        SearchBar
      },
      setup() {
        const companies = ref([]);
        
    
        const fetchCompanies = async () => {
          const q = query(collection(db, 'companies'), where('showRecord', '==', true), orderBy('companyName'));
          const querySnapshot = await getDocs(q);
          companies.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        };
  
        const handleError = (index) => {
        companies.value[index].companyAvatar = companies.value[index].companyLogo || 'https://example.com/default-profile.png';
        // Force the image to reload by changing its key
        const imgElement = document.querySelectorAll('.card-img-top')[index];
        if (imgElement) {
          imgElement.src = companies.value[index].companyLogo || 'https://example.com/default-profile.png';
        }
      };
    
        onMounted(fetchCompanies);
  
        return { companies, handleError };
  
        
      }
    };
  
  </script>
    
  <style>
  .logo_card {
    width: 128px;
    height: 128px;
    margin-top: -100px !important;
    margin: auto;
  }
  .info_card {
    margin-top: 100px;
  }
  </style>
    